
import {
  defineComponent, onMounted, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import XLSX from 'xlsx';
import { ElLoading } from 'element-plus';

import DashboardLayout from '@/layouts/DashboardLayout.vue';
import {
  Chart,
  PageHeader,
} from '@/components/Shared';
import CustomizeDialog from '@/components/CustomizeDialog/CustomizeDialog.vue';
import { FavoritesFormDialog } from '@/components/Forms';
import {
  DataGrid,
  DataGridDrawer,
  DataGridPagination,
} from '@/components/DataGrid';
import { InvoiceNotesDialog } from '@/components/Invoice';

import useExport from '@/uses/useExport';
import useFavorite from '@/uses/useFavorite';
import useGrid from '@/uses/useGrid';
import useInvoice from '@/uses/useInvoice';
import useView from '@/uses/useView';

import { IOrderRequest } from '@/interfaces/Order';
import { IFilter } from '@/interfaces/Filter';
import { IAction, IColumn } from '@/interfaces/View';

export default defineComponent({
  name: 'ViewDashboard',

  components: {
    CustomizeDialog,
    FavoritesFormDialog,
    Chart,
    DashboardLayout,
    DataGrid,
    DataGridDrawer,
    DataGridPagination,
    InvoiceNotesDialog,
    PageHeader,
  },

  setup() {
    const route = useRoute();

    const details = ref(null);

    const expandedRowKeys = ref();

    const {
      exportResponse,
      exportRequest,
      resetExportRequest,
      setExportRequestFilters,
      setExportRequestOrders,
      setExportRequestPageSize,
      fetchExportData,
    } = useExport();

    const {
      view,
      charts,
      columns,
      hiddenColumns,
      metrics,
      dataSource,
      gridName,
      drillThrough,
      hasNotes,
      gridFilters,
      gridOrders,
      actions,
      fetchView,
      fetchDrillThrough,
      fetchFavorite,
      removeGridFilters,
      removeGridColumnFilters,
    } = useView();

    const { createFavorite } = useFavorite();

    const {
      grid,
      gridRequest,
      fetchGridData,
      updateGridColumns,
      updateGridFilters,
      updateGridOrders,
      addGridRequestFilter,
      removeGridRequestFilter,
      removeGridRequestFilters,
      removeGridColumnRequestFilters,
      setGridRequestFilters,
      setGridRequestOrders,
      setGridRequestPageSize,
      setGridRequestPageIndex,
      setGridRequestDrillThrough,
      setGridRequestDrillThroughParentView,
      setGridRequestDrillThroughParentComponent,
      setGridRequestDrillThroughDataRow,
      resetGridRequest,
    } = useGrid();

    const { dynamicInvoiceRequest } = useInvoice();

    const customizeDialog = ref<InstanceType<typeof CustomizeDialog>>();

    const openCustomizeDialog = ():void => {
      // eslint-disable-next-line no-unused-expressions
      customizeDialog.value?.open();
    };

    const invoiceNotesDialog = ref<InstanceType<typeof InvoiceNotesDialog>>();

    // eslint-disable-next-line camelcase
    const openInvoicesDialog = ():void => {
      // eslint-disable-next-line no-unused-expressions
      invoiceNotesDialog.value?.open();
    };

    const favoritesFormDialog = ref<InstanceType<typeof FavoritesFormDialog>>();

    const openFavoritesFormDialog = ():void => {
      // eslint-disable-next-line no-unused-expressions
      favoritesFormDialog.value?.open();
    };

    const dataGrid = ref<InstanceType<typeof DataGrid>>();

    const dataGridDrawer = ref<InstanceType<typeof DataGridDrawer>>();

    const activeActionName = ref<string>('');

    const toggleDrawer = ($event): void => {
      details.value = $event;
      // eslint-disable-next-line no-unused-expressions
      dataGridDrawer.value?.open();
    };

    const changePage = async (page: number): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        setGridRequestPageIndex(page);
        await fetchGridData({
          viewName: view.value.name,
          gridName: gridName.value,
          gridRequest: gridRequest.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
        const elements = document.getElementsByClassName('el-table__body-wrapper');
        if (elements) {
          elements[0].scrollTo(0, 0);
        }
        const main = document.getElementsByClassName('el-main');
        if (main) {
          main[0].scrollTo(0, 0);
        }
      }
    };

    const changeLimit = async (limit: number): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        setGridRequestPageSize(limit);
        setGridRequestPageIndex(0);
        await fetchGridData({
          viewName: view.value.name,
          gridName: gridName.value,
          gridRequest: gridRequest.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    const changeSort = async ({ prop, order }: { prop: string; order: string }): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        if (order) {
          const sort: IOrderRequest = {
            id: prop,
            type: order === 'ascending' ? 'asc' : 'desc',
          };
          setGridRequestOrders(sort);
          if (!route.query.dataRow) {
            await updateGridOrders({
              viewName: view.value.name,
              componentName: gridName.value,
              orders: gridRequest.value.orders,
            });
          }
          await fetchGridData({
            viewName: view.value.name,
            gridName: gridName.value,
            gridRequest: gridRequest.value,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    const addFilter = async (value: Record<string, string>): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        addGridRequestFilter({
          id: value.id,
          type: value.type,
          index: 0,
          visible: true,
          field_id: value.id,
          operation: value.operation,
          data_source: dataSource.value,
          filter_text: value.value,
          header_text: '',
          default_filter: '',
        });
        setGridRequestPageIndex(0);
        if (!route.query.dataRow) {
          await updateGridFilters({
            viewName: view.value.name,
            componentName: gridName.value,
            filters: gridRequest.value.filters,
          });
        }
        await fetchGridData({
          viewName: view.value.name,
          gridName: gridName.value,
          gridRequest: gridRequest.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    const removeFilter = async (value: IFilter): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        removeGridRequestFilter(value);
        if (!route.query.dataRow) {
          await updateGridFilters({
            viewName: view.value.name,
            componentName: gridName.value,
            filters: gridRequest.value.filters,
          });
        }
        setGridRequestPageIndex(0);
        await fetchGridData({
          viewName: view.value.name,
          gridName: gridName.value,
          gridRequest: gridRequest.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    const resetColumnFilters = async (payload: string): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        removeGridColumnRequestFilters(payload);
        removeGridColumnFilters(payload);
        if (!route.query.dataRow) {
          await updateGridFilters({
            viewName: view.value.name,
            componentName: gridName.value,
            filters: gridRequest.value.filters,
          });
        }
        setGridRequestPageIndex(0);
        await fetchGridData({
          viewName: view.value.name,
          gridName: gridName.value,
          gridRequest: gridRequest.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    const resetFilters = async (): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        removeGridRequestFilters();
        removeGridFilters();
        setGridRequestPageIndex(0);
        if (!route.query.dataRow) {
          await updateGridFilters({
            viewName: view.value.name,
            componentName: gridName.value,
            filters: gridRequest.value.filters,
          });
        }
        await fetchGridData({
          viewName: view.value.name,
          gridName: gridName.value,
          gridRequest: gridRequest.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    const queryGridData = async (): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        if (!route.query.dataRow) {
          // combine the two columns
          const updateColumns = columns.value.concat(hiddenColumns.value);
          await updateGridColumns({
            viewName: view.value.name,
            componentName: gridName.value,
            columns: updateColumns,
          });
          await updateGridFilters({
            viewName: view.value.name,
            componentName: gridName.value,
            filters: gridRequest.value.filters,
          });
          await updateGridOrders({
            viewName: view.value.name,
            componentName: gridName.value,
            orders: gridRequest.value.orders,
          });
        }

        await fetchGridData({
          viewName: view.value.name,
          gridName: gridName.value,
          gridRequest: gridRequest.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    const refreshData = async (): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        await fetchGridData({
          viewName: view.value.name,
          gridName: gridName.value,
          gridRequest: gridRequest.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    const saveNote = async (): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        if (dataGrid.value && dataGrid.value.multipleSelection) {
          expandedRowKeys.value = dataGrid.value.multipleSelection;
          if (view.value.name === 'invoices') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const invoiceNumbers = expandedRowKeys.value.map((el) => el.invoice_id);

            const currentAction = actions.value.find(
              (el: IAction) => el.command === activeActionName.value,
            );

            if (currentAction && invoiceNotesDialog.value) {
              await dynamicInvoiceRequest({
                url: currentAction.endpoint.url,
                method: currentAction.endpoint.method,
                data: {
                  update_values: {
                    notes: invoiceNotesDialog.value.note,
                    invoice_status_indicator: invoiceNotesDialog.value.status,
                  },
                  invoice_id: invoiceNumbers,
                  command: currentAction.command,
                },
              });
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
        // eslint-disable-next-line no-unused-expressions
        invoiceNotesDialog.value?.close();
        // eslint-disable-next-line no-unused-expressions
        dataGrid.value?.clearMultipleSelection();
        refreshData();
      }
    };

    const saveFavorite = async (payload: string): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        await createFavorite({ viewName: view.value.name, favoriteName: payload });
        // eslint-disable-next-line no-unused-expressions
        favoritesFormDialog.value?.close();
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    };

    const triggerDynamicAction = (actionName: string): void => {
      activeActionName.value = actionName;
      if (actionName === 'mass_update_invoices') {
        openInvoicesDialog();
      }
    };

    const exportXlsx = async (): Promise<void> => {
      console.error('Exporting has started');
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        setExportRequestFilters(gridRequest.value.filters);
        if (gridRequest.value.orders.length > 0) {
          setExportRequestOrders([...gridRequest.value.orders]);
        }
        setExportRequestPageSize(grid.value.pagination.total_rows);
        await fetchExportData({
          viewName: view.value.name,
          gridName: gridName.value,
          exportRequest: exportRequest.value,
        });
        const exportColumns = columns.value.map(
          (column: IColumn) => ({
            label: column.header_text,
            field: column.id,
            column_type: column.type,
          }),
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const createXLSLFormatObj: any = [];
        if (exportColumns.length === 0) {
          return;
        }
        if (exportResponse.value.rows.length === 0) {
          return;
        }

        const newXlsHeader = exportColumns.map((column) => column.label);

        createXLSLFormatObj.push(newXlsHeader);

        exportResponse.value.rows.forEach((row): void => {
          const innerRowData: (string | number | Date)[] = [];
          exportColumns.forEach((column) => {
            if (column.column_type === 'numeric'
                || column.column_type === 'int4'
            ) {
              innerRowData.push((row[column.field] * 1));
            } else if (column.column_type === 'date') {
              innerRowData.push(row[column.field] ? new Date(row[column.field]) : '');
            } else if (column.column_type === 'money') {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              innerRowData.push(row[column.field] ? Number(row[column.field]?.toString()?.replace(/[$,]/g, '')) : 0);
            } else {
              innerRowData.push(row[column.field]);
            }
          });
          createXLSLFormatObj.push(innerRowData);
        });

        const filename = `${view.value.name}.xlsx`;
        const wsName = view.value.name;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

        const formatColumn = (column: number, format: string) => {
          if (ws['!ref']) {
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let row = range.s.r + 1; row <= range.e.r; row += 1) {
              const pos = XLSX.utils.encode_cell({ r: row, c: column });
              if (format === '$#,###.00') {
                ws[pos].t = 'n';
              }
              if (ws[pos]) {
                ws[pos].z = format;
              }
            }
          }
        };

        exportColumns.forEach((column, index) => {
          if (column.column_type === 'numeric') {
            formatColumn((index), '0');
          }
          if (column.column_type === 'int4') {
            formatColumn((index), '0');
          }
          if (column.column_type === 'money') {
            formatColumn((index), '$#,###.00');
          }
          if (column.column_type === 'date') {
            formatColumn((index), 'm/d/yy');
          }
        });

        XLSX.utils.book_append_sheet(wb, ws, wsName);
        XLSX.writeFile(wb, filename, { cellStyles: true, cellDates: true });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
        resetExportRequest();
      }
    };

    const resetDataGrid = () => {
      // eslint-disable-next-line no-unused-expressions
      dataGrid.value?.resetDataGrid();
    };

    onMounted(async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        const { favorite } = route.query;
        if (favorite) {
          resetGridRequest();
          await fetchFavorite({
            viewName: view.value.name
              ? view.value.name : route.params.id.toString(),
            favoriteName: favorite.toString(),
          });
        } else if (route.query.dataRow && route.query.gridName) {
          resetGridRequest();
          setGridRequestDrillThrough({
            parent_view: view.value.name
              ? view.value.name : route.params.id.toString(),
            parent_component: route.query.gridName.toString(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data_row: Object.fromEntries(new URLSearchParams(route.query.dataRow)),

          });
          await fetchDrillThrough({
            parent_view: view.value.name
              ? view.value.name : route.params.id.toString(),
            parent_component: route.query.gridName.toString(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data_row: Object.fromEntries(new URLSearchParams(route.query.dataRow)),
          });
        } else {
          resetGridRequest();
          setGridRequestDrillThrough(null);
          await fetchView(route.params.id.toString());
        }

        if (dataSource.value && gridName.value) {
          if (gridFilters.value && gridFilters.value.length > 0) {
            setGridRequestFilters(gridFilters.value);
          }

          if (gridOrders.value && gridOrders.value.length > 0) {
            setGridRequestOrders(gridOrders.value[0]);
            // eslint-disable-next-line no-unused-expressions
            dataGrid.value?.setDefaultSort(gridOrders.value[0]);
          }
          setGridRequestPageIndex(0);
          setGridRequestPageSize(100);
          await fetchGridData({
            viewName: view.value.name
              ? view.value.name : route.params.id.toString(),
            gridName: gridName.value,
            gridRequest: gridRequest.value,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      view,
      charts,
      columns,
      metrics,
      dataSource,
      grid,
      gridName,
      drillThrough,
      hasNotes,
      gridFilters,
      gridOrders,
      gridRequest,
      customizeDialog,
      invoiceNotesDialog,
      favoritesFormDialog,
      dataGrid,
      dataGridDrawer,
      details,
      expandedRowKeys,
      actions,
      fetchView,
      fetchFavorite,
      fetchGridData,
      toggleDrawer,
      openCustomizeDialog,
      triggerDynamicAction,
      openFavoritesFormDialog,
      changePage,
      changeLimit,
      changeSort,
      addFilter,
      removeFilter,
      queryGridData,
      saveNote,
      saveFavorite,
      refreshData,
      exportXlsx,
      resetFilters,
      resetColumnFilters,
      setGridRequestFilters,
      setGridRequestOrders,
      removeGridRequestFilters,
      setGridRequestPageIndex,
      setGridRequestPageSize,
      fetchDrillThrough,
      setGridRequestDrillThrough,
      setGridRequestDrillThroughParentView,
      setGridRequestDrillThroughParentComponent,
      setGridRequestDrillThroughDataRow,
      resetGridRequest,
      resetDataGrid,
    };
  },

  created() {
    document.addEventListener('visibilitychange', async () => {
      await this.fetchGridData({
        viewName: this.view.name,
        gridName: this.gridName,
        gridRequest: this.gridRequest,
      });
    }, false);
  },

  async beforeRouteUpdate(to, from, next) {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    try {
      if (to.query.dataRow && to.query.gridName) {
        this.resetGridRequest();
        this.setGridRequestDrillThrough({
          parent_view: to.params.id.toString(),
          parent_component: to.query.gridName.toString(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data_row: Object.fromEntries(new URLSearchParams(to.query.dataRow)),
        });
        await this.fetchDrillThrough({
          parent_view: to.params.id.toString(),
          parent_component: to.query.gridName.toString(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data_row: Object.fromEntries(new URLSearchParams(to.query.dataRow)),
        });
      } else {
        this.resetGridRequest();
        this.setGridRequestDrillThrough(null);
        await this.fetchView(from.params.id.toString());
      }

      if (this.dataSource && this.gridName) {
        if (this.gridFilters && this.gridFilters.length > 0) {
          this.setGridRequestFilters(this.gridFilters);
        }
        if (this.gridOrders && this.gridOrders.length > 0) {
          this.setGridRequestOrders(this.gridOrders[0]);
          // eslint-disable-next-line no-unused-expressions
            this.dataGrid?.setDefaultSort(this.gridOrders[0]);
        }
        this.setGridRequestPageIndex(from.query.page ? Number(from.query.page) : 0);
        this.setGridRequestPageSize(from.query.page ? Number(from.query.size) : 100);

        await this.fetchGridData({
          viewName: this.view.name
            ? this.view.name : this.$route.params.id.toString(),
          gridName: this.gridName,
          gridRequest: this.gridRequest,
        });
      }

      if (from.query.elementsHeight) {
        const elements = document.getElementsByClassName('el-table__body-wrapper');
        const main = document.getElementsByClassName('el-main');
        elements[0].scrollTo(0, Number(from.query.elementsHeight));
        main[0].scrollTo(0, Number(from.query.mainHeight));
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingInstance.close();
      next();
    }
  },

  watch: {
    '$route.query': {
      handler() {
        const elements = document.getElementsByClassName('el-table__body-wrapper');
        const main = document.getElementsByClassName('el-main');

        if (this.$route.query.dataRow) {
          if (elements) {
            elements[0].scrollTo(0, 0);
          }
          if (main) {
            main[0].scrollTo(0, 0);
          }
        }
      },
      deep: true,
    },
  },
});
